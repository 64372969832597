@use "../styles/theme.scss";

:local(.back-button), :local(.lights-button) {
  position: absolute;
  top: 16px;
  pointer-events: auto;

  svg {
    color: theme.$overlay-text-color;
  }

  @media(min-width: theme.$breakpoint-lg) and (min-height: theme.$breakpoint-vr) {
    display: none;
  }
}

:local(.back-button) {
  left: 8px;
}

:local(.lights-button) {
  right: 8px;
}

:local(.object-menu-container) {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  pointer-events: auto;
}

:local(.object-menu) {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  width: max-content;
  color: theme.$overlay-text-color;
  border-radius: 12px;

  svg {
    color: theme.$overlay-text-color;
  }
}

:local(.header) {
  display: none;
  height: 48px;
  align-items: center;
  justify-content: center;

  @media(min-width: theme.$breakpoint-lg) and (min-height: theme.$breakpoint-vr) {
    display: flex;
  }
}

:local(.close-button) {
  position: absolute;
  left: 16px;
}

:local(.lights-header-button) {
  position: absolute;
  right: 16px;
}


:local(.menu) {
  display: flex;
  padding: 16px;

  @media(min-width: theme.$breakpoint-lg) and (min-height: theme.$breakpoint-vr) {
    padding-top: 0;
  }
}

:local(.object-menu-button) {
  height: 36px;
  padding: 0 16px;
  color: theme.$overlay-text-color;
}

:local(.separator) {
  width: 1px;
  margin: 0 4px;
  background-color: theme.$overlay-border-color;
}

:local(.pagination) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: theme.$overlay-text-color;
  margin-top: 24px;
  padding: 0 4px;

  svg {
    color: theme.$overlay-text-color;
  }

  @media(min-width: theme.$breakpoint-lg) and (min-height: theme.$breakpoint-vr) {
    display: none;
  }
}

:global(.keyboard-user)  {
  :local(.back-button):focus, :local(.object-menu-container) :focus {
    box-shadow: 0 0 0 3px theme.$overlay-outline-color;
  }
}
