:local(.Modal) {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  user-select: none;
  & * {
    user-select: none;
  }
}

:local(.ModalOverlay) {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: -1;
  background-color: rgba(#000, 0.5);
}

:local(.ModalWrapper) {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  border-radius: 16px;
  background-color: rgba(36, 56, 73, .8);
  // backdrop-filter: blur(10px);
  box-shadow: 0px 4px 16px rgba(235, 255, 0, .7), 0px 0px 48px rgba(0, 0, 0, .15);
  transform: translate(-50%, -50%);
  width: 1000px;
  max-width: 90vw;
}

:local(.ModalInnerWrapper) {
  position: relative;
  z-index: 1;
  padding-top: 56.25%;
  height: 0;
  border-radius: inherit;
}

:local(.banner) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

:local(.closeBtn) {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  overflow: hidden;
  transform: translate(50%, -50%);
  background-color: rgba(36, 56, 73, .8);
  backdrop-filter: blur(10px);
  // box-shadow: 0px 4px 16px rgba(235,255,0,.7), 0px 0px 48px rgba(0,0,0,.15);
  color: white;
  font-size: 16px;
  line-height: 1;
  font-family: "SB Sans Display", sans-serif;
  cursor: pointer;
  transition: opacity 0.35s ease-out;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
}


:local(.shareBtn) {
  position: absolute;
  bottom: -10px;
  left: 50%;
  border-radius: 42px;
  padding: 18px;
  transform: translate(-50%, 100%);
  background-color: rgba(36, 56, 73, .8);
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 16px rgba(235, 255, 0, .7), 0px 0px 48px rgba(0, 0, 0, .15);
  color: white;
  font-size: 16px;
  line-height: 1;
  font-family: "SB Sans Display", sans-serif;
  transition: opacity 0.35s ease-out;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
    color: white;
  }
}

@media screen and (max-height: 780px) {
  :local(.ModalWrapper) {
    width: 700px;
  }
}

@media screen and (max-height: 600px) {
  :local(.ModalWrapper) {
    width: 500px;
  }
}

@media screen and (max-height: 500px) {
  :local(.ModalWrapper) {
    width: 350px;
  }
}

@media screen and (max-height: 500px) {
  :local(.ModalWrapper) {
    transform: translate(-50%, -65%);
  }
}