:local(.modal) {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100svw;
  height: 100svh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #000;
  z-index: 80000;
  -webkit-animation: show-animation 0.5s;
  animation: show-animation 0.5s;
}

:local(.text){
  display: inline-block;
  position: relative;
  font-size: 24px;
  color: #ffffff;
  text-align: center;

  &::after{
    position: absolute;
    left: 100%;
    bottom: 0;
    content: '';
    -webkit-animation: loader-animation 2s infinite linear;
    animation: loader-animation 2s infinite linear;
  }
}

:local(.storybook-btn){
  color: #000;
  z-index: 80002;
}

@keyframes loader-animation {
  0%   { content: ''; }
  16%  { content: '.'; }
  32%  { content: '..'; }
  48%  { content: '...'; }
  64%  { content: '\00a0..'; }
  75%  { content: '\00a0\00a0.'; }
}

@keyframes show-animation {
  from{opacity: 0.2; top: 50%; left: 50%; width: 0; height: 0; border-radius: 100%;}
  to{opacity: 1; top: -50%; left: -50%; width: 200svw; height: 200svh; }
}
