@use "../styles/theme.scss";

:local(.list) {
  display: flex;
  flex: 1;
  flex-shrink: 1;
  flex-direction: column;
  list-style: none;
  overflow-y: auto;
}

:local(.list-item) {
  display: flex;
}

:local(.list-item-content) {
  align-items: center;
  padding: 16px 24px;
}

:local(.button-list-item) {
  display: flex;
  background-color: transparent;
  border: none;
  width: 100%;

  &:hover {
    background-color: theme.$list-bg-color-hover;
  }

  &:active {
    background-color: theme.$list-bg-color-pressed;
  }

  :global(body.keyboard-user) &:focus {
    box-shadow: inset 0 0 0 3px theme.$outline-color;
  }

  &:local(.selected) {
    color: theme.$active-text-color;
    background-color: theme.$active-color;

    svg {
      color: theme.$active-text-color;
    }

    &:hover {
      background-color: theme.$active-color-hover;
    }
  
    &:active {
      background-color: theme.$active-color-pressed;
    }
  }
}
