@use "../styles/theme.scss";


:local(.data-tile) {
  border: 2px;  
  border-color: theme.$white;
  border-style: solid;
  padding: 4px;
  margin: 4px;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  flex: 0 1 auto;
}

:local(.data-tile-title) {
  color: theme.$white;
  font-size: theme.$font-size-sm;
  margin: 0;
  text-align: center;
  font-weight: theme.$font-weight-bold;
  display: block;
}