@font-face {
  font-family: 'SB Sans Display';
  font-style: normal;
  font-weight: 200;
  src: url(./SBSansDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SB Sans Interface';
  font-style: normal;
  font-weight: 300;
  src: url(./SBSansUI-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'SB Sans Display Bold';
  font-style: normal;
  font-weight: 400;
  src: url(./SBSansDisplay-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'SB Sans Display Thin';
  font-style: normal;
  font-weight: 300;
  src: url(./SBSansDisplay-Thin.ttf) format('truetype');
}