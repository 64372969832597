@use "../styles/theme.scss";

:local(.content-menu) {
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // background-color: #F2F2F2;
  // border: 1px solid #F2F2F2;
  border-radius: 32px;
  // width: 64px;
  // height: 64px;
  pointer-events: auto;
  user-select: none;
  & * {
    user-select: none;
  }

  // @media(min-width: theme.$breakpoint-lg) {
  //   top: 38px;
  //   right: 24px;
  // }

  // @media screen and (max-width: 600px) {
  //   padding-top: 78px;
  // }
}

:local(.content-menu-button) {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 32px;
  padding: 0;
  width: 64px;
  height: 64px;
  font-size: theme.$font-size-xs;
  font-weight: theme.$font-weight-bold;


  & > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:hover {
    background-color: theme.$button-bg-color-hover;
  }

  &:active {
    background-color: theme.$button-bg-color-pressed;
  }

  svg {
    color: theme.$text1-color;
  }
}

:local(.active) {
  color: theme.$text5-color;
  background-color: theme.$active-color;

  svg {
    color: theme.$text5-color;
  }

  &:hover {
    background-color: theme.$active-color-hover;
  }

  &:active {
    background-color: theme.$active-color-pressed;
  }
}

:local(.separator) {
  width: 1px;
  margin: 0 8px;
  background-color: theme.$border1-color;
}
