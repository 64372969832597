@import 'shared';

:local(.treatment) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

:local(.screenshot) {
  position: absolute;
  width: 115%;
  height: 115%;
  top: -40px;
  left: -40px;

  img {
    width: 100%;
    height: 100%;
  }

  opacity: 0.8;
}

:local(.hub-name) {
  color: black;
  text-shadow: 0px 0px 10px #888;
  font-weight: bold;
  font-size: 1.8em;
  margin-bottom: 16px;
}

:local(.main-panel) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: auto;
}

:local(.logo) {
  position: absolute;
  top: 16px;
  left: 8px;
  width: 175px;
  filter: drop-shadow(0 0 4px #888);

  img {
    width: 100%;
  }
}
