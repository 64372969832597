@use "../styles/theme.scss";

:local(.flex-row) {
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

:local(.space-between) {
  justify-content: space-between;
}

$breakpoints: (
  "sm": theme.$breakpoint-sm,
  "md": theme.$breakpoint-md,
);

@each $size, $breakpoint in $breakpoints {
  // .sm-breakpoint-column
  :local(.#{$size}-breakpoint-column) {
    @media(max-width: $breakpoint) {
      display: block;
    }
  }
}

:local(.no-flex-wrap) {
  flex-wrap: nowrap;
}

$spacing: (
    "2xs": theme.$spacing-2xs,
    "xs": theme.$spacing-xs,
    "sm": theme.$spacing-sm,
    "md": theme.$spacing-md,
    "lg": theme.$spacing-lg,
    "xl": theme.$spacing-xl
);

@each $size, $theme-spacing in $spacing {
  :local(.#{$size}-children-margin-r) > * {
    margin-right: $theme-spacing;
  }
  :local(.#{$size}-children-margin-r) > :last-child {
    margin-right: 0
  }
}
