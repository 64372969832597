@use "../../react-components/styles/theme.scss";
@import 'shared';

* {
  box-sizing: border-box;
}

html, body, #ui-root, .home-root {
  margin: 0;
  height:100%;
}

body {
  @extend %default-font;
  background: theme.$background1-color;
  color: theme.$text1-color;
}

#ui-root, .home-root {
  display: flex; 
  flex-direction: column;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

small {
  font-size: 0.8rem;
}
