@use '../styles/theme';

:local(.toolbar) {
  // height: #{theme.$toolbar-height}px;
  display: flex;
  padding: 12px;
  // background-color: theme.$background1-color;
  // border-top: 1px solid theme.$border1-color;

  position: absolute;
  z-index: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  background: transparent;
  border: none;
  pointer-events: none;
  
  > * {
    pointer-events: all;
  }

  user-select: none;
  & * {
    user-select: none;
  }

  @media (min-width: theme.$breakpoint-lg) {
    padding: 12px 24px;
    justify-content: space-between;
  }
}

:local(.content) {
  display: flex;
  flex: 1;
  justify-content: space-around;

  @media (min-width: theme.$breakpoint-lg) {
    flex: 0;

    & > * {
      margin-right: 24px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
}
