@use "../styles/theme";

:local(.label) {
  margin-bottom: 8px;
  color: theme.$text3-color;
  align-self: flex-start;
}

:local(.input-field) {
  display: flex;
  flex-direction: column;
  width: 100%;
  //max-width: 300px;
  white-space: break-spaces;
  line-height: 1.2rem;

  @media screen and (max-height: 450px) and (orientation: landscape) {
    margin: 0px 7px 0px 7px;
  }
}

:local(.fullWidth) {
  max-width: 100%;
}

:local(.error) {
  margin-top: 8px;
  color: theme.$error-color;
  align-self: flex-start;
}

:local(.info) {
  margin-top: 8px;
  color: theme.$text3-color;
  align-self: flex-start;
}

:local(.info),
:local(.error) {
  font-size: 10px;
}
